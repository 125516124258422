import {mapActions, mapGetters } from "vuex";
import axios from "@/axios";
import moment from "moment";
export default {
    computed: {
        ...mapGetters(["getNeoCaseId"])
    },
    methods: {
        ...mapActions([
            "getQueryMonitoringEvents"
        ]),
        viewQuery({query}) {
            let name = Object.keys(query).filter((n) => n == "name");
            if (query.sources.includes("lexis")) {
                if (query.person_entity?.full_name || query.person_entity?.first_name || query.person_entity?.last_name) {
                    name = query.person_entity?.full_name || ((query.person_entity?.first_name || '') + ' ' + (query.person_entity?.last_name || '')).trim()
                }
                else if (query.company_entity?.name){
                    name = query.company_entity.name
                }
                query = {name: name, entity: query.entity_type}
            }
            if (name) {
                query = {name: query["name"], ...query};
            }
           
            return Object.entries(query)
                .map((n) => (n[0] != "page_size" && n[0] != "start" ? n[1] : ""))
                .filter((n) => n)
                .join(", ");
          },
        getToolsData(toolName) {
            let tabName = "tt"
            let dataRes= this.getAllToolsData;
            dataRes = dataRes?.find((n) => n["tool-name"] === toolName);
            if (dataRes) {
                const request_id = dataRes?.request_id;
                let match;
                if (tabName === "monitoring" || tabName === "monitoring-ro") match = this.toolSearchQueries?.find((el) => el._id === request_id && el.is_monitoring);
                else match = this.toolSearchQueries?.find((el) => el._id === request_id);
                if (match)
                    Object.entries(dataRes).forEach(([key, value]) => {
                        this[key] = value;
                    });
            }
            return dataRes;
        },

        setToolsData(toolName) {
            this.SET_ALL_TOOLS_DATA({
                ...this._data,
                "tool-name": toolName,
                entityId: this.currentEntity
            });
            // if (tabName === "tools") {
            //     this.SET_ALL_TOOLS_DATA({
            //         ...this._data,
            //         "tool-name": toolName,
            //     });
            // } else {
            //     this.SET_ALL_MONITORING_DATA({
            //         ...this._data,
            //         "tool-name": toolName,
            //     });
            // }
        },
        updateMonitoringCascadingTimeline(data) {
            // const data = timeline.concat(timeline).concat(timeline).concat(timeline).concat(timeline)
            // .concat(timeline).concat(timeline).concat(timeline).concat(timeline);
            // Object.assign(this.dateFilters, {
            //     data: data || [],
            // });
            if (data.newData) {
                let timeline = this.dateFilters.data.timeline;
                const match = timeline.findIndex((el) => el.api_all_data.doc_id === data.newData.api_all_data.doc_id);
                timeline[match] = data.newData;
                Object.assign(this.dateFilters, {
                    data: {
                        timeline: timeline,
                        meta_data: this.dateFilters.data.meta_data,
                    },
                });
            } else {
                Object.assign(this.dateFilters, {
                    data: data || {
                        timeline: [],
                        meta_data: null,
                    },
                });
            }
        },
        getEndDate(days) {
            var date = new Date();
            date.setDate(date.getDate() + days);
            return date;
        },

        async setMonitoring(notAllowed) {
            const url = '/service/upcoming-review-date'
            const startDate = new Date()
            const endDate = this.getEndDate(180)
            let dateFormat = moment(startDate)
                    .format("YYYY-MM-DD")
                    .split("-")
                    .join(""); 
            let range_occurence = {
                range_type: "end_date",
                start_date: moment(startDate).format("YYYY-MM-DD"),
                end_date: moment(endDate).format("YYYY-MM-DD"),
            };
            
            let payload = {
                state_name: "Monitoring",
                case_id: this.getNeoCaseId,
                recurrence: {
                    pattern: {
                        pattern_type: "daily",
                        interval: 1,
                    },
                    range_occurence: range_occurence,
                },
                review_freq_format: `DTSTART:${dateFormat} RRULE:FREQ=DAILY;INTERVAL=1`,
            };
            if (notAllowed) {
                payload = {
                    state_name: "To Do",
                    case_id: this.getNeoCaseId,
                }
            }
            await axios.post(url, payload)
        }
    },
};
